import { ETypePersonneJuridique } from "common/enums/ClientSheet/ETypePersonneJuridique";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSheetAddManyTemplateToFileRequestResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetAddManyTemplateToFileRequestResource";
import ProductSheetAddTemplateToFileRequestResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetAddTemplateToFileRequestResource";
import ProductSheetTemplateFileResponseResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetTemplateFileResponseResource";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import FileUploaderComponent, { EFileTypes } from "components/elements/FileUploaderComponent";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import HistoryTemplateFileModal from "./HistoryTemplateFileModal";
import ViewerTemplateFileModal from "./ViewerTemplateFileModal";

const productService = container.resolve(ProductService);

type IProps = {
	productId: string;
	typePersonneJuridique: ETypePersonneJuridique;
	documentTypes: EProductDocumentType[];
};

type DocumentTypeToFiles = Record<EProductDocumentType, { inititalFile: FileResponseResource | null; file: FileResponseResource | null }>;

export default function TemplateFiles(props: IProps) {
	const { productId, documentTypes, typePersonneJuridique } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [documentTypeToFiles, setDocumentTypeToFiles] = useState<DocumentTypeToFiles>(getDocumentTypeToFiles(documentTypes, []));
	const viewerModal = useOpenable();
	const historyModal = useOpenable();
	const [selectedFile, setSelectedFile] = useState<FileResponseResource | null>(null);
	const [selectedType, setSelectedType] = useState<EProductDocumentType | null>(null);
	const [productSheets, setProductSheets] = useState<ProductSheetResponseResource[]>([]);

	useEffect(() => {
		console.log({ typePersonneJuridique });
		productService.getTemplateFiles(productId, typePersonneJuridique).then((templateFiles) => setDocumentTypeToFiles(getDocumentTypeToFiles(documentTypes, templateFiles)));
		productService.getAllProductSheetsByProductId(productId).then(setProductSheets);
	}, [documentTypes, productId, typePersonneJuridique]);

	const uploadFile = useCallback(async (file: File, documentType: EProductFileType | ESubFilesTypes | EProductDocumentType) => {
		return productService.uploadTemplateFile(file).then((file) =>
			setDocumentTypeToFiles((_documentTypeToFiles) => ({
				..._documentTypeToFiles,
				[documentType as EProductDocumentType]: { ..._documentTypeToFiles[documentType as EProductDocumentType], file },
			})),
		);
	}, []);

	const onFileDeleted = useCallback((fileId: string, documentType: EProductDocumentType, inititalFile: FileResponseResource | null) => {
		if (fileId === inititalFile?.id) return;
		productService
			.deleteFile(fileId)
			.then(() => {
				setDocumentTypeToFiles((_documentTypeToFiles) => ({
					..._documentTypeToFiles,
					[documentType]: { ..._documentTypeToFiles[documentType], file: inititalFile },
				}));
			})
			.catch((error) => console.warn(error));
	}, []);

	const onSubmit = useCallback(
		(
			e: React.FormEvent<HTMLFormElement>,
			_formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			setErrors([]);
			const templateFiles = getTemplateFiles(typePersonneJuridique, documentTypeToFiles);
			if (!templateFiles || !productId) return;
			ProductSheetAddManyTemplateToFileRequestResource.hydrate<ProductSheetAddManyTemplateToFileRequestResource>({
				productId,
				templateFiles,
			})
				.validateOrReject()
				.then((resource) => productService.addManyTemplateFiles(resource))
				.then(() => productService.getTemplateFiles(productId, typePersonneJuridique))
				.then((templateFiles) => setDocumentTypeToFiles(getDocumentTypeToFiles(documentTypes, templateFiles)))
				.then(() => productService.getAllProductSheetsByProductId(productId).then(setProductSheets))
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[documentTypeToFiles, documentTypes, productId, typePersonneJuridique],
	);

	const hasChanged = useMemo(
		() =>
			Object.keys(documentTypeToFiles).some((key) => {
				const documentType = key as EProductDocumentType;
				const file = documentTypeToFiles[documentType].file;
				const inititalFile = documentTypeToFiles[documentType].inititalFile;
				return file?.id !== inititalFile?.id;
			}),
		[documentTypeToFiles],
	);

	const onClickViewer = useCallback(
		(file: FileResponseResource | null) => {
			if (!file) return;
			setSelectedFile(file);
			viewerModal.open();
		},
		[viewerModal],
	);

	const onClickHistory = useCallback(
		(file: FileResponseResource | null, type: EProductDocumentType) => {
			if (!file) return;
			setSelectedFile(file);
			setSelectedType(type);
			historyModal.open();
		},
		[historyModal],
	);

	return (
		<div className={classes["root"]}>
			<Form className={classes["form"]} onSubmit={onSubmit} errors={errors}>
				<section>
					{Object.keys(documentTypeToFiles).map((key) => {
						const documentType = key as EProductDocumentType;
						const file = documentTypeToFiles[documentType].file;
						const inititalFile = documentTypeToFiles[documentType].inititalFile;
						return (
							<FileUploaderComponent
								key={documentType}
								title={I18n.asset.enums.EProductDocumentType[documentType]}
								fileType={documentType}
								uploadFile={uploadFile}
								onFileDeleted={(fileId) => onFileDeleted(fileId, documentType, inititalFile)}
								defaultFiles={file ? [file] : []}
								filesAccepted={[EFileTypes.PDF]}
								cannotDelete={file?.id === inititalFile?.id}
								onClickViewer={file ? () => onClickViewer(file) : undefined}
								onClickHistory={file ? () => onClickHistory(file, documentType) : undefined}
							/>
						);
					})}
				</section>
				<Button type="submit" disabled={!hasChanged}>
					{I18n.asset.common.save}
				</Button>
			</Form>
			{selectedFile && <ViewerTemplateFileModal isOpen={viewerModal.isOpen} onClose={viewerModal.close} label={selectedFile.name} fileId={selectedFile.id} />}
			{selectedType && selectedFile && (
				<HistoryTemplateFileModal
					isOpen={historyModal.isOpen}
					onClose={historyModal.close}
					type={selectedType}
					productSheets={productSheets}
					personneJuridique={typePersonneJuridique}
				/>
			)}
		</div>
	);
}

function getDocumentTypeToFiles(documentTypes: EProductDocumentType[], templateFiles: ProductSheetTemplateFileResponseResource[]): DocumentTypeToFiles {
	return documentTypes.reduce((acc, documentType) => {
		const templateFile = templateFiles.find((file) => file.type === documentType);
		acc[documentType] = { inititalFile: templateFile?.file || null, file: templateFile?.file || null };
		return acc;
	}, {} as DocumentTypeToFiles);
}

function getTemplateFiles(
	typePersonneJuridique: ETypePersonneJuridique,
	documentTypeToFiles: DocumentTypeToFiles,
): ProductSheetAddTemplateToFileRequestResource[] | null {
	const templateFiles = Object.keys(documentTypeToFiles).map((key) => {
		const type = key as EProductDocumentType;
		const fileId = documentTypeToFiles[type].file?.id;
		if (!fileId) return null;
		return ProductSheetAddTemplateToFileRequestResource.hydrate<ProductSheetAddTemplateToFileRequestResource>({
			type,
			typePersonneJuridique,
			fileId,
		});
	});

	return templateFiles.filter((r) => r !== null) as ProductSheetAddTemplateToFileRequestResource[];
}
